<template>
  <div class="flex_column_evenly">
    <div class="flex_row_center">
      <i class="incfont el-icon-title"></i>
      <span lang="AdvancedSetting" class="margin_left">高级设置</span>
    </div>
    <div class="flex_row_center">
      <el-checkbox 
      v-model="longDistance" 
      @change="handleLongDistanceChange">
      <span lang="LongDistance">远距离模式 </span>
      </el-checkbox>
      <span class="tips_font_size" lang="LongDistanceTips">(远距离模式下，距离会更远抗干扰能力更强，相应工作电流会加大，使用时间减少)</span>
    </div>
    <tips ref="tipsDialog" :text="tipsText"></tips>
  </div>  
</template>

<script>
  import Tips from '@/components/Dialog/Tips.vue'
  import HIDHandle from '@/assets/js/HIDHandle'
  export default {
    components: {
      Tips,
    },
    data() {
      return {
        longDistance:false,
        tipsText:''
      }
    },
    methods: {
      async handleLongDistanceChange() {
        var tmp = this.longDistance;
        if(tmp) {
          this.$refs.tipsDialog.visible = true;
        }
        else {
          if(await HIDHandle.Set_Device_LongDistance(0) == false) {
            this.longDistance = true;
          }
        }
      }
    },
    created() {
      this.$bus.$on("updateMouseUI",value => {
        this.longDistance = value.longDistance;
      });

      this.$bus.$on("languageChange",lang => {
        if(typeof lang != 'undefined') {
          this.tipsText = lang.LongDistanceTips;
        }
      });

      this.$bus.$on("tipsResult",async value => { 
        if(this.$refs.tipsDialog.visible) {
          if(value == true) {
            if(await HIDHandle.Set_Device_LongDistance(1) == false) {
              this.longDistance = false;
            }
          }
          else {
            this.longDistance = false;
          }
        }    
      }); 
    },
    beforeDestroy() {
      this.$bus.$off('updateMouseUI');
      this.$bus.$off('tipsResult');
      this.$bus.$off('languageChange');
    }
  }
</script>

<style lang="less" scoped>
.tips_font_size {
  font-size: calc(var(--font-size) * 0.8);
}
</style>