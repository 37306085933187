<template>
  <div class="flex_column_evenly">
    <div class="flex_row_center">
      <i class="incfont el-icon-title"></i>
      <span lang="DeviceInfo" class="margin_left">设备信息</span>
    </div>
    <div class="flex_row_center">
      <span lang="DongleVersion">接收器固件版本</span>
      <span>&nbsp;{{dongleVersion}}</span>
    </div>
    <div class="flex_row_center">
      <span lang="MouseVersion">鼠标固件版本</span>
      <span>&nbsp;{{mouseVersion}}</span>
    </div>     
  </div> 
</template>

<script>
  export default {
    data() {
      return {
        dongleVersion:' v1.06',
        mouseVersion:'v1.07',
        language:{}
      }
    },
    created() {
      this.$bus.$on("updateVersion",value => {
        this.dongleVersion = value.dongle;
        this.mouseVersion = value.device;
      });
    },
    beforeDestroy() {
      this.$bus.$off("updateVersion");
    }
  }
</script>

<style lang="less" scoped>
  
</style>