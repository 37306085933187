// router/index.js
import Vue from 'vue';
import Router from 'vue-router';
import App from '@/App.vue';
import Compx from '@/router/Compx/Compx.vue';
import Lamzu from '@/router/Lamzu/Lamzu.vue';
import Visit from './Visit/Visit.vue';

Vue.use(Router);
 
export default new Router({
  mode: 'history', // 使用history模式
  base:'./',
  routes: [
    {
      path: '/',
      name: 'app',
      component: App,
    },
    {
      path: '/compx',
      name: 'compx',
      component: Compx,
      children:[
        {
          path: '*',
          redirect: '../compx'
        }
      ]
    },
    {
      path: '/lamzu',
      name: 'lamzu',
      component: Lamzu,
      children:[
        {
          path: '*',
          redirect: '../lamzu'
        }
      ]
    },
    // {
    //   path: '/visit',
    //   name: 'visit',
    //   component: Visit,
    //   children:[
    //     {
    //       path: '*',
    //       redirect: '../visit'
    //     }
    //   ]
    // },
    // ...其他路由
  ]
});