<template>
  <el-dialog
    ref="fire-key"
    :title="title"
    :visible.sync="visible"
    custom-class="dialog_class"
    @open="onOpen"
    @close="onClose">
    <span slot="footer" class="dialog-footer">
      <div class="flex_column_around_center">
        <span class="dialog_span_padding" lang="DialogShortcutKeyTips"></span>
        <el-input
        v-model="key"
        :disabled="true"
        style="width: 25vw; margin-top: 1vh;"></el-input>
        <div class="flex_row content_between" style="width: 25vw;margin-top: 1vh;">
            <el-checkbox 
            v-for="(item,index) in modifyItems"
            :key="index"
            v-model="modifys"
            :label="item"
            @change="handleModifyChange">
            </el-checkbox>
        </div>
      </div>
      <div class="flex_row_center content_evenly" style="margin-top: 3vh;">
        <el-button @click="handleOKClick" class="dialog_button" lang="OK">确 定</el-button>
        <el-button @click="handleCancelClick" class="dialog_button" lang="Cancel">取 消</el-button>
      </div>
    </span>
  </el-dialog>
</template>
  
<script>
  import HIDKey from '@/assets/js/HIDKey';

  export default {
    extends: 'el-dialog',
    props: {
      title: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        visible:false,
        key:'',
        modifys:[],
        modifyItems:["Shift","Ctrl","Alt","Win"],
        result:{},
      }
    },
    methods: {
      onOpen() {
        // 打开对话框时的逻辑
        this.result = {
          result:false,
        };
        window.addEventListener('keydown', this.handleKeyDown);
      },
      onClose() {
        // 关闭对话框时的逻辑
        window.removeEventListener('keydown', this.handleKeyDown);
        console.log("shortcut key close",this.result);
        this.$bus.$emit("shortcutKey",this.result);
      },
      handleKeyDown(event) {
        var hid = HIDKey.keyToHID(event.code);
        if(hid != null) {
          if(hid.type == 1) {
            this.key = HIDKey.keyToHID(event.code).text;
            console.log(event,this.key);
            event.preventDefault();    
          }
        }
      },
      handleModifyChange() {
        //把第一个modify key弹出
        if(this.modifys.length > 2) {
          this.modifys.splice(0,1);
        }
        console.log("modifys:",this.modifys);
      },
      handleOKClick() {
        for(var i = 0;i < this.modifys.length;i++) {
          var modify = "L" + this.modifys[i];
          this.modifys[i] = modify;
        }

        if(this.key != '') {
          this.modifys.push(this.key);
        }

        if(this.modifys.length > 0) {
          this.result = {
            result:true,
            modifys:this.modifys,
          }
        }
        this.visible = false;
      },
      handleCancelClick() {
        this.visible = false;
      }
    },
    created() {
      this.$bus.$on('backToHome',value => {
        if(value) {
          if(this.visible) {
            this.visible = false;
          }
        }
      });
    },
    beforeDestroy() {
      this.$bus.$off('backToHome');
    }
  }
</script>

<style lang="less" scoped>
  .el-input_width {
    width: calc(var(--font-size) * 6);
  }

  :deep(.el-input.is-disabled .el-input__inner) {
    border: 1px solid var(--border-color);
    color: var(--font-color);
    cursor: text;
  }
</style>