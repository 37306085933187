<template>
  <!-- 按键防抖时间设置 -->
  <div class="flex_column">
    <span lang="DebounceTime">按钮防抖时间</span>
    <select 
    v-model="debounceTime"
    @change="handleDebounceTimeChange"
    class="cascader_width">
      <option 
        v-for="item in debounceTimeOptions" 
        :key="item.value" 
        :value="item.value">
        {{ item.option }}
      </option>
    </select>
    <tips ref="tipsDialog" :text="tipsText"></tips>
  </div>
</template>

<script>
  import Tips from '@/components/Dialog/Tips.vue'
  import HIDHandle from '@/assets/js/HIDHandle'
  
  export default {
    components: {
      Tips
    },
    data() {
      return {
        debounceTimeUint:"ms",
        debounceTime:8,
        lastDebounceTime:8,
        debounceTimeOptions:[],
        debounceTipsFlag:false,
        tipsText:'',
        tipsDebounce:8,
      }
    },
    methods: {
      //按钮防抖时间
      async handleDebounceTimeChange() {
        //如果按键消抖时间<8ms，弹窗提示
        if(this.debounceTime < this.tipsDebounce) {
          if(this.debounceTipsFlag == false) {
            this.debounceTipsFlag = true;
            this.$refs.tipsDialog.visible = true;
          }
        }

        if(await HIDHandle.Set_MS_DebounceTime(this.debounceTime)) {
          this.lastDebounceTime = this.debounceTime;
        }
        else {
          this.debounceTime = this.lastDebounceTime;
        }
      },
    },
    created() {
      this.$bus.$on("setMouseDefaultCfg",deviceCfg => {
        this.lastDebounceTime = this.debounceTime = deviceCfg.debounce;
        this.tipsDebounce = deviceCfg.tipsDebounce;
        this.debounceTipsFlag = false;
        this.debounceTimeOptions = [];
        for(var i = 0;i <= deviceCfg.maxDebounce;i++) {
          var tmp = {
            value:i,
            option:i + this.debounceTimeUint
          }
          this.debounceTimeOptions.push(tmp);
        }
      });

      this.$bus.$on("updateMouseUI",value => {
        this.lastDebounceTime = this.debounceTime = value.debounceTime;
      });

      this.$bus.$on("languageChange",lang => {     
        if(typeof lang != 'undefined') {
          this.debounceTimeUint = lang.DebounceTimeUint;

          this.tipsText = lang.DialogDebounceTips;
        }
      });
    },
    beforeDestroy() {
      this.$bus.$off("languageChange");
    }
  }
</script>

<style lang="less" scoped>

</style>