<template>
  <div class="flex_column">
    <div class="flex_row_center">
      <i class="incfont el-icon-title"></i>
      <span lang="SleepTime" class="margin_left">鼠标休眠时间</span>
    </div>
    <select 
    v-model="sleepTime" 
    @change="handleSleepTimeChange"
    class="margin_top">
      <option 
        v-for="item in sleepTimes" 
        :key="item.value" 
        :value="item.value">
        {{ item.option }}
      </option>
    </select>    
  </div> 
</template>

<script>
  import HIDHandle from '@/assets/js/HIDHandle'

  export default {
    data() {
      return {
        sleepTimes:[],
        sleepTime:1,
        lastSleepTime:1,
      }
    },
    methods: {
      async handleSleepTimeChange() {      
        if(await HIDHandle.Set_MS_LightOffTime(this.sleepTime)) {
          this.lastSleepTime = this.sleepTime;
          this.$bus.$emit("updateLightOffTime",this.sleepTime);
        }
        else {
          this.sleepTime = this.lastSleepTime;
        }
      },
    },
    created() {   
      this.$bus.$on("setMouseDefaultCfg",deviceCfg => {
       this.lastSleepTime = this.sleepTime = deviceCfg.sleepTime;
      }); 

      this.$bus.$on("updateMouseUI",value => {
        this.lastSleepTime = this.sleepTime = value.sleepTime;
      });

      this.$bus.$on("updateSleepTime",value => {
       this.lastSleepTime = this.sleepTime = value;
      }); 

      this.$bus.$on("languageChange",lang => {
        if(typeof lang != 'undefined') {
          this.lastSleepTime = this.sleepTimes = lang.LightOffTimeOptions;
        }
      });
    },
    beforeDestroy() {
      this.$bus.$off("setMouseDefaultCfg");
      this.$bus.$off("updateMouseUI");
      this.$bus.$off("languageChange");
      this.$bus.$off("updateSleepTime");
    }
  }
</script>

<style lang="less" scoped>

</style>