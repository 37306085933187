<template>
  <div class="flex_row_center normal_width" style="align-items: start;">
    <div class="flex_column_around_center content_between normal_height">

      <div class="flex_column">
        <div class="flex_row_center"
        v-for="(item,index) in keys" 
        :key="index">
          <!--左侧索引按钮-->
          <el-button class="index_button">{{index + 1}}</el-button>
          <!--按键功能显示-->
          <template
            v-if="refreshing">
            <el-cascader 
              v-model="keys[index]"
              :ref="`cascader${index}`"
              :options="keyOptions" 
              placeholder=""
              @change="handleKeyValueChange(index, ...arguments)"
              @focus="handleKeyFocus(index,)"
              :show-all-levels="false"
              style="margin: 5px;">
              <template slot-scope="{ node,data }">
                <span 
                @click="handleSpanClick(node,data,index)"
                style="display: block;width: 100%;">{{data.label}}</span>
              </template>    
            </el-cascader>    
          </template>  
        </div>
      </div>

      <!-- 按键防抖时间设置 -->
      <debounce></debounce>
    </div>

    <!-- 中间鼠标图片 -->
    <div :style="{backgroundImage:'url(' + path + ')'}"
    class="mouse_img">
      <!-- 设置各个按钮的位置 -->
      <div 
      v-for="(item,index) in keyLocs"
      :key="index">
        <el-button 
        @click="handleMouseNumberClick(index)"
        :style="{top:item.top,left:item.left}"
        class="key_button">{{index + 1}}</el-button>
      </div>
    </div>

    <!--  -->
    <div class="flex_column_around_center content_between normal_height">
      <profile></profile>
      <other></other>
    </div>
    
    <tips
    ref="tipsDialog"
    :text="tipsText" ></tips>

    <fire-key 
    ref="fireKeyDialog"></fire-key>

    <shortcut-key 
    ref="shortcutKeyDialog"></shortcut-key>
  </div>
</template>

<script>
  import Debounce from '@/components/Key/Debounce.vue';
  import Profile from '@/components/Key/Profile.vue'
  import Other from '@/components/Key/Other.vue'

  import HIDHandle from '@/assets/js/HIDHandle';
  import Tips from '@/components/Dialog/Tips.vue'
  import FireKey from '@/components/Dialog/FireKey.vue';
  import ShortcutKey from '@/components/Dialog/ShortcutKey.vue';
  import UserConvert from '@/assets/js/UserConvert';
import HIDKey from '@/assets/js/HIDKey';

  export default {
    components: {
      Debounce,
      Profile,
      Other,
      Tips,
      FireKey,
      ShortcutKey
    },
    data() {
      return {
       path:'/img/devices/mouse3e01.png',
       
       refreshing:false,
       selectIndex:0,
       keyOptions:[],
       defaultKeyLocs:[],
       keyLocs:[],
       defaultKeys:[],
       keys:[],
       lastKeys:[],
       widthRatio:1,
       heightRatio:1,

       language:{},
       tipsText:'',
       times:2,
       interval:10,

       macros:[],

       initFlag:false
      }
    },
    methods: {
      //选择相同的触发
      handleKeyFocus(index) {
        var onlyOneLeftKey = false;
        this.selectIndex = index;
        this.lastKeys = this.keys[index];
        console.log("handleKeyFocus:",this.selectIndex,this.lastKeys,this.keys);
        var leftKeys = [];
        for(var i = 0;i < this.keys.length;i++) {
          if(this.keys[i][0] == HIDHandle.MouseKeyFunction.MouseKey && 
             this.keys[i][1] == HIDHandle.MouseKeyFunction.LeftKey) {
              leftKeys.push(i);
            }
        }

        if(leftKeys.length == 1) {
          if(leftKeys[0] == index) {
            onlyOneLeftKey = true;
            this.$refs.tipsDialog.visible = true;
            this.tipsText = this.language.DialogKeepLeftKey;
          }
        }

        return onlyOneLeftKey;
      },
      //点击按钮下拉框,选择相同的触发
      async handleSpanClick(node,data,index) {
        console.log("span Click",node,data,index); 
        this.selectIndex = index;

        if((node.hasChildren == false) && (node.parent == null)) {
          //组合键
          if(data.value == HIDHandle.MouseKeyFunction.ShortcutKey) {
            var isComboKey = false;
            var shortcut;
            if(this.keys[index][0] == HIDHandle.MouseKeyFunction.ShortcutKey) {
              var keyIndex = this.defaultKeys[index].index;
              shortcut = HIDHandle.deviceInfo.mouseCfg.shortCutKey[keyIndex];

              isComboKey = shortcut.isMedia == false;
            }
            
            if(isComboKey) {
              var modifys = [];
              var key = '';
              for(var i = 0;i < shortcut.contexts.length;i++) {
                var hid = HIDKey.HIDToKey(shortcut.contexts[i]);
                if(hid.type == 0) {
                  modifys.push(hid.text.replace(/L/g,''));
                }
                else {
                  key = hid.text;
                }
              }

              this.$refs.shortcutKeyDialog.modifys = modifys;
              this.$refs.shortcutKeyDialog.key = key;
            }
            else {
              this.$refs.shortcutKeyDialog.modifys = [];
              this.$refs.shortcutKeyDialog.key = '';
            }

            this.$refs.shortcutKeyDialog.visible = true;
          }

          //火力键
          if(data.value == HIDHandle.MouseKeyFunction.FireKey) {
            if(this.keys[index][0] == HIDHandle.MouseKeyFunction.FireKey) {
              var keyIndex = this.defaultKeys[index].index;
              var value = UserConvert.String_To_Hex(HIDHandle.deviceInfo.mouseCfg.keys[keyIndex][1]); 
              this.$refs.fireKeyDialog.times = value & 0xFF;
              this.$refs.fireKeyDialog.interval = value >> 8;
            }
            else {
              this.$refs.fireKeyDialog.times = 3;
              this.$refs.fireKeyDialog.interval = 10;
            }
            this.$refs.fireKeyDialog.visible = true;
          }
        }        
      },
      async handleKeyValueChange(selectIndex) {
        var value = this.keys[selectIndex];
        var index = this.defaultKeys[selectIndex].index;

        this.selectIndex = selectIndex;
        var setKeyfuction = false;
        var macroName;
        var type = value[0];
        var param = 0x0000;
        //多媒体键
        if(value[0] == 1005) {
          type = 0x05;
          await HIDHandle.Set_MS_Multimedia(index,value[1]);
          setKeyfuction = true;
        }
        else
        {
          type = UserConvert.String_To_Hex(value[0]);
          console.log("handleKeyValueChange",selectIndex,index,value); 
          //多媒体
          if(type == HIDHandle.MouseKeyFunction.ShortcutKey) {

          }
          //宏
          else if(type == HIDHandle.MouseKeyFunction.Macro) {
            // var macroIndex = UserConvert.String_To_Hex(value[1]);
            // macroName = this.macros[macroIndex].name;
            var macroIndex = 0;
            macroName = value[1];
            for(macroIndex = 0;macroIndex < this.macros.length;macroIndex++) {
              if(this.macros[macroIndex].name == macroName) {
                break;
              }
            }
            param = (index << 8) + parseInt(this.macros[macroIndex].cycleTimes);
            setKeyfuction = true;
          }
          else if(type == HIDHandle.MouseKeyFunction.FireKey) {

          }
          else {
            //禁用键，鼠标键，DPI切换，鼠标左右滚，切换报告率，开关装饰灯，切换配置，鼠标上下滚
            param = UserConvert.String_To_Hex(value[1]);
            setKeyfuction = true;
          }
        }
          

        if(setKeyfuction) {
          var keyFunction = {
            type : type,
            param:param
          }

          await HIDHandle.Set_MS_KeyFunction(index,keyFunction);

          if(type == HIDHandle.MouseKeyFunction.Macro) {
            console.log("set macro",macroName,this.keys);
            this.$bus.$emit("setMouseMacro",index,macroName);
          }
        }
      },
      handleMouseNumberClick(index) {
        if(this.handleKeyFocus(index) == false) {
          this.$refs[`cascader${index}`][0].toggleDropDownVisible();
        }
      },
      //初始化时修改按钮位置
      changeKeysLocation() {
        if(this.initFlag) {
          this.keyLocs = [];
          for(var i = 0;i < this.keys.length;i++)
          {
            var loc = 
            {
              left: this.defaultKeyLocs[i].left * this.widthRatio  + 'px',
              top: this.defaultKeyLocs[i].top * this.widthRatio + 'px'
            }
            this.keyLocs.push(loc);
          }
        }
      },
      updateMacros() {
        var macros = [];
        
        for(var i = 0; i < this.keyOptions.length; i++)
        {
          if(this.keyOptions[i].value == HIDHandle.MouseKeyFunction.Macro)
          {
            this.keyOptions[i].children = [];
            for(var j = 0;j < this.macros.length;j++)
            {
              var children = {
                value:this.macros[j].name,
                label:this.macros[j].name
              }
              macros.push(children);
              this.keyOptions[i].children.push(children);
            }
          }
        }

        if(macros.length > 0) {
          this.refreshing = false;
          var value = HIDHandle.deviceInfo.mouseCfg;
          for(var i = 0;i < this.keys.length;i++) {
            var index = UserConvert.String_To_Hex(this.defaultKeys[i].index);

            if(value.keys[i][0] == HIDHandle.MouseKeyFunction.Macro) {
              for(var j = 0;j < macros.length;j++) {
                if(macros[j].label == value.macros[i].name) {
                  var macro = [HIDHandle.MouseKeyFunction.Macro.toString(),macros[j].value];
                  this.keys[index] = macro;
                }                              
              }
            }
          }
          this.refreshing = true;
          console.log("keyOptions",this.keyOptions,this.keys,macros);
        }
      }
    },
    created() {
      this.$bus.$on("setMouseDefaultCfg",deviceCfg => {
        this.keys.length = deviceCfg.keys.length;
        for(var i = 0;i < deviceCfg.keys.length;i++)
        {
          this.keys[i] = deviceCfg.keys[i].value;
        }

        this.defaultKeys = deviceCfg.keys;
        this.defaultKeyLocs = [];
        for(var i = 0;i < this.keys.length;i++)
        {
          var loc = 
          {
            left: deviceCfg.keys[i].loc[0]  + 'px',
            top: deviceCfg.keys[i].loc[1]  + 'px'
          }
          this.keyLocs.push(loc);

          var loc1 = {
            left: deviceCfg.keys[i].loc[0] ,
            top: deviceCfg.keys[i].loc[1] 
          }
          this.defaultKeyLocs.push(loc1);
        }
        this.initFlag = true;    
        this.refreshing = true;

        this.changeKeysLocation();
      });

      this.$bus.$on("setDevicePath",path => {
        this.path = window.location.href + "img/devices/" + path;
        console.log("setDevicePath",this.path);
      }); 

      //加载鼠标的按键
      this.$bus.$on("updateMouseUI",value => {
        this.refreshing = false;
        for(var i = 0;i < this.keys.length;i++) {
          var index = UserConvert.String_To_Hex(this.defaultKeys[i].index);

          if((value.keys[i][0] == HIDHandle.MouseKeyFunction.FireKey) || 
             (value.keys[i][0] == HIDHandle.MouseKeyFunction.ProfileSwitch) ||
             (value.keys[i][0] == HIDHandle.MouseKeyFunction.ReportRateSwitch) || 
             (value.keys[i][0] == HIDHandle.MouseKeyFunction.Disable)) {
              this.keys[index] = value.keys[i][0];
          }
          else if(value.keys[i][0] == HIDHandle.MouseKeyFunction.Macro) {

          }
          else if(value.keys[i][0] == HIDHandle.MouseKeyFunction.ShortcutKey) {
            // var key = [HIDHandle.MouseKeyFunction.ShortcutKey.toString()];
            // if(value.shortCutKey[i].isMedia) {
            //   key.push(value.shortCutKey[i].contexts[0].value);
            // }

            var key = [];
            if(value.shortCutKey[i].isMedia) {
              key.push("1005");
              key.push(value.shortCutKey[i].contexts[0].value);
            }
            else {
              key.push(HIDHandle.MouseKeyFunction.ShortcutKey.toString());
            }
            this.keys[index] = key;
          }
          else {
            this.keys[index] = value.keys[i];
          }
        }

        this.refreshing = true;
      });

      //更新宏名称列表
      this.$bus.$on("restoreKeyfunction",async(index) => {

        for(var i = 0;i < this.keys.length;i++) {
          if(UserConvert.String_To_Hex(this.defaultKeys[i].index) == index) {
            var value = UserConvert.String_To_Hex(this.defaultKeys[i].value[1]);
            var type = UserConvert.String_To_Hex(this.defaultKeys[i].value[0]);
            var key = [this.defaultKeys[i].value[0],this.defaultKeys[i].value[1]];
            if(type == 1005) {
              type = 5;
            }
            var keyFunction = {
              type : type,
              param:value
            }

            if(await HIDHandle.Set_MS_KeyFunction(index,keyFunction)) {
              this.keys[i] = key;
            }      
          }
        }
        this.refreshing = false;
        console.log("restoreKeyfunction",this.keyOptions,this.keys,this.defaultKeys);
        this.refreshing = true;
      });   

      this.$bus.$on("languageChange",lang => {     
        if(typeof lang != 'undefined') {
          //获取默认报告率列表
          this.language = lang;

          this.refreshing = false;
          this.keyOptions = lang.KeyOptions;
          this.updateMacros();
          this.refreshing = true;
        }
      });

      this.$bus.$on("mouseImageResize",value => {
        this.widthRatio = value;
        this.changeKeysLocation();
      });  

      //火力键弹窗确定
      this.$bus.$on("fireKey",value => {
        if(value.result) {
          this.times = value.times;
          this.interval = value.interval;

          var keyFunction = {
            type : HIDHandle.MouseKeyFunction.FireKey,
            param: (value.interval << 8) + value.times,
          }

          HIDHandle.Set_MS_KeyFunction(this.defaultKeys[this.selectIndex].index,keyFunction);
        }
        else {
          this.refreshing = false;
          this.keys[this.selectIndex] = this.lastKeys;
          this.refreshing = true;
        }
      });

      //组合键弹窗确定
      this.$bus.$on("shortcutKey",async(value) => {
        if(value.result) {
          var data = Uint8Array.of(0x00, 0x00, 0x00);
          data[0] = HIDHandle.MouseKeyFunction.ShortcutKey;
          console.log("shortcut:",value);

          var keyFunction = {
            type : HIDHandle.MouseKeyFunction.ShortcutKey,
            param:0x0000,
          }

          await HIDHandle.Set_MS_KeyFunction(this.defaultKeys[this.selectIndex].index,keyFunction);

          await HIDHandle.Set_MS_ShortcutKey(this.defaultKeys[this.selectIndex].index,value.modifys);
        }
        else {
          this.refreshing = false;
          console.log("shortcut key not set:",this.keys,this.lastKeys,this.selectIndex);
          this.keys[this.selectIndex] = this.lastKeys;
          this.refreshing = true;          
        }
      });     
      
      //更新宏名称列表
      this.$bus.$on("updateMacroList",async(value) => {
        this.macros = value;
        this.updateMacros();
      });         
    },
    beforeDestroy() {
      this.$bus.$off("setMouseDefaultCfg");
      this.$bus.$off("setDevicePath");      
      this.$bus.$off("updateMouseUI");
      this.$bus.$off("updateKeyMacro");
      this.$bus.$off("updateMacroList");
      this.$bus.$off("restoreKeyfunction");

      this.$bus.$off("languageChange");
      this.$bus.$off("mouseImageResize");

      this.$bus.$off("fireKey");
      this.$bus.$off("shortcutKey");
    }
  }
</script>

<style lang="less" scoped>
 .normal_height {
  height: 70vh;
 }

 .normal_width {
  width: 70vw;
 }
</style>