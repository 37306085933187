<template>
  <div class="flex_column_evenly">
    <div class="flex_row_center">
      <i class="incfont el-icon-title"></i>
      <span lang="PairTool" class="margin_left">配对工具</span>
    </div>
    <el-button :disabled="disablePair" @click="handlePairClick" class="import_button_width"><span lang="Pair">配对</span></el-button>

    <pair-dialog ref="pairDialog"></pair-dialog>
  </div> 
</template>

<script>
  import PairDialog from '../Dialog/PairDialog.vue';

  export default {
    components: {
      PairDialog
    },
    data() {
      return {
        pairDialogState:false,
        disablePair:false
      }
    },
    methods: {
      handlePairClick() {
        // if(this.pairDialogState == false) {
        //   this.pairDialogState = true;
           this.$refs.pairDialog.visible = true;
        // }
      },
      handleKeyDown(event) {
        console.log("handleKeyDown",event);
        if (event.code === 'Space') {
          // 执行某些操作
          //this.handlePairClick();
        }
      }
    },
    watch: {
      '$refs.pairDialog.visible': {
        handler() {
          console.log("visible",this.$refs.pairDialog.visible);
        }
      }
    },
    created() {
      this.$bus.$on("setMouseDefaultCfg",deviceCfg => {
        // window.removeEventListener('keydown', this.handleKeyDown);
        // window.addEventListener('keydown', this.handleKeyDown);
      });
      this.$bus.$on("updateDeviceInfo",value => {
        this.disablePair = value.isWired;
      });
    },
    beforeDestroy() {
      this.$bus.$off("setMouseDefaultCfg");
    }
  }
</script>

<style lang="less" scoped>

</style>