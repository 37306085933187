<template>
  <div class="flex_row content_between">

    <!-- 宏列表 -->
    <div class="flex_column_evenly content_between macro_table_width table_height">
      <span class="flex_column_start" lang="MacroList">宏列表</span>
      <div>
        <el-table
        :data="macros"  
        @row-click="handleMacroRowClick" 
        @row-contextmenu="handleMacroRowContextmenu"  
        :row-class-name="macroRowName"
        height="sundry_height"
        class="sundry_height">
        <el-table-column min-width="85%">
          <template slot-scope="scope">
            <span style="display: flex;width: 100%;">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column
        min-width="15%"
          align="right">
          <template slot-scope="scope">
            <el-button
            @click="handleModifyMacroClick(scope.$index)"
            class="mini_button"
            icon="el-icon-edit-outline"></el-button>
          </template>
        </el-table-column>
        </el-table>
      </div>
      <div class="flex_row content_between">
        <el-button 
        @click="handleNewMacroClick"
        class="macro_button_width"><span lang="NewMacro">新建宏</span></el-button>
        <el-button 
        @click="handleDeleteMacroCLick"
        class="macro_button_width"><span lang="Delete">删除</span></el-button>
      </div>
    </div>

    <!-- 按键列表 -->
    <div class="flex_column_evenly content_between macro_table_width table_height">
      <span class="flex_column_start" lang="KeyList">按键列表</span>
      <div>
        <el-table
        ref="keyTable"
        :data="contexts"
        @current-change="handleKeyCurrentChange"  
        :row-class-name="keyRowName"
        height="sundry_height"
        class="sundry_height">
        <el-table-column>
          <template slot-scope="scope">
            <div class="flex_row_center">
              <i class="el-icon-top" v-show="scope.row.status == 1"></i>
              <i class="el-icon-bottom" v-show="scope.row.status == 0"></i>
              <el-input 
              v-if="currentContextIndex == scope.$index && modifyFlag" 
              v-model="tmpKeyValue"
              ref="inputModifyKey"
              @focus="handleModifyKeyFocus">{{getKeyText(scope.row)}}</el-input>
              <span 
              v-else  
              class="margin_left">{{getKeyText(scope.row)}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <div style="display: flex; flex-direction: row;align-items: center;">
              <i class="el-icon-time margin_left"></i>
              <el-input 
              v-if="currentContextIndex == scope.$index && modifyFlag" 
              v-model.number="tmpKeyDelay"
              max="65535"
              type="number"
              @input="handleModifyDelayInput">{{scope.row.delay}}</el-input>
              <span 
              v-else  
              class="span_delay_size">{{scope.row.delay}}</span>
            </div>
          </template>
        </el-table-column>
        </el-table>
      </div>
      <div class="flex_row content_between">
        <el-button 
        @click="handleModifyKeyCLick"
        class="macro_button_width"><span lang="Modify">修改</span></el-button>
        <el-button 
        @click="handleDeleteKeyClick"
        class="macro_button_width"><span lang="Delete">删除</span></el-button>
      </div>
    </div>

    <div class="flex_column_start table_height" style="justify-content: space-evenly;">
      <el-button @click="handleRecordClick" class="macro_record_width"><span :class="recordClass"></span> {{recordLabel}}</el-button>

      <div class="flex_column_start delay_height">
        <el-radio v-model="delayAction" label="1" class="flex_row_center"><span lang="AutoDelay">自动插入延时</span></el-radio>
        <el-radio v-model="delayAction" label="2" class="flex_row_center"><span lang="DefaultDelay">默认延时</span></el-radio>
        <el-input :disabled="isRecording" v-model="defaultDelay" @blur="handleDefaultDelayBlur" placeholder="10-65535" class="macro_record_width"></el-input>
      </div>

      <div class="flex_column_start cycle_height">
        <el-radio v-removeAriaHidden v-model="cycleAction" label="254" class="flex_row_center"> <span lang="UntilThisReleased">循环直到此按键松开</span></el-radio>
        <el-radio v-removeAriaHidden v-model="cycleAction" label="255" class="flex_row_center"><span lang="UntilAnyPressed">循环直到任意键按下</span></el-radio>
        <el-radio v-removeAriaHidden v-model="cycleAction" label="253" class="flex_row_center"><span lang="UntilThisPressed">循环直到此按键再次按下</span></el-radio>
        <el-radio v-removeAriaHidden v-model="cycleAction" label="1" class="flex_row_center"> <span lang="CycleTimes">循环次数</span></el-radio>
        <el-input :disabled="isRecording" v-model="cycleTimes" @blur="handleCycleTimesBlur" placeholder="1-250" class="macro_record_width"></el-input>
      </div>

      <div class="flex_column_start insert_height">
        <span lang="InsertEvent">插入事件</span>
        <el-dropdown 
        v-model="insertCommand" 
        @command="handleInsertEventCommand"
        trigger="click" 
        class="macro_record_width" 
        style="border: 1px solid;">
          <span class="el-dropdown-link macro_record_width flex_center macro_record_height"><i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
            v-for="(item,index) in insertEventOptions"
            :key="index"
            :command=item.command>{{item.option}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button 
        @click="handleSaveClick"
        class="macro_record_width"
        :class="saveTipsFlag ? 'highlight_button' : 'normal_button'"
        :disabled="saveTipsFlag == false"><span lang="Save">保存</span></el-button>
      </div>   
    </div>

    <tips
    ref="tipsDialog"
    :text="tipsText"></tips>

    <input-name
    ref="inputName"
    :text="inputNameText"></input-name>

    <input-key
    ref="inputKey"></input-key>
  </div>
</template>

<script>
  import Tips from '@/components/Dialog/Tips.vue';
  import InputName from '@/components/Dialog/InputName.vue';
  import InputKey from '@/components/Dialog/InputKey.vue';

  import HIDKey from '@/assets/js/HIDKey';
  import UserConvert from '@/assets/js/UserConvert';
  import HIDHandle from '@/assets/js/HIDHandle';

  export default {
    components: {
      Tips,
      InputName,
      InputKey,
    },
    data() {
      return {
        macros:[],
        currentMacroIndex:-1,
        macroChange:false,

        contexts:[],
        currentContextIndex:-1,
        contextCount:0,
        lastTimeStamp:0,
        keyInterval:0,
        restartRecord:false,
        tmpKeyValue:'',
        inputKeyValue:'',
        tmpKeyDelay:10,
        modifyFlag:false,
      
        isRecording: false,
        recordLabel:'开始录制',
        recordClass:'circle',
        saveTipsFlag:false,
        saveMacroFirst:false,

        delayAction:"1",
        defaultDelay:'10',
        cycleAction:'1',
        cycleTimes:1,
        
        insertCommand:'0',
        insertEventOptions:[],
        macroKeyType:'modify',

        language:{},
        tipsText:'',
        inputNameText:'',
        newMacroName:false,
      }
    },
    methods: {
      //请先停止宏录制
      stopRecordFirst() {
        if(this.isRecording) {
          this.tipsText = this.language.DialogStopRecordFirst;
          this.$refs.tipsDialog.visible = true;
        }

        return this.isRecording;
      },
      //宏选择发生变化
      handleMacroRowClick(val) {
        if(val != null) {
          if(this.isRecording == false) {
            this.currentMacroIndex = this.macros.indexOf(val);
            this.contexts = UserConvert.Deep_Clone_Array(val.contexts);//val.contexts;
            this.cycleAction = val.cycleTimes < 251 ? "1" : val.cycleTimes.toString();
            if(this.cycleAction == "1") {
              this.cycleTimes = val.cycleTimes.toString();
            }
            else {
              this.cycleTimes = "1";
            }
            this.currentContextIndex = -1;
            this.saveTipsFlag = false;
            this.macroChange = true;
          }
        }
      },
      handleMacroRowContextmenu(val,column,event) {
        console.log("handleMacroRowContextmenu",val,column,event);
      },
      //新建宏
      handleNewMacroClick() {
        if(this.stopRecordFirst() == false) {
          this.newMacroName = true;
          this.$refs.inputName.visible = true;
        }
      },
      //删除宏
      handleDeleteMacroCLick() {
        if(this.stopRecordFirst() == false) {
          if (this.currentMacroIndex != -1) {
            this.tipsText = this.language.DialogDeleteMacro;
            this.$refs.tipsDialog.visible = true;
          }
        }
      },
      //修改宏名称
      handleModifyMacroClick(index) {
        if(this.stopRecordFirst() == false) {
          this.currentMacroIndex = index;
          this.newMacroName = false;
          this.$refs.inputName.visible = true;
        }
      },
      //按键选择发生变化
      handleKeyCurrentChange(val) {
        console.log("handleKeyCurrentChange",val)
        if(val != null) {
          this.currentContextIndex = this.contexts.indexOf(val);
          this.tmpKeyValue = this.getCurrentKeyValue();
          this.tmpKeyDelay = this.contexts[this.currentContextIndex].delay;
          this.modifyFlag = false;
        }
        else {
          this.currentContextIndex = -1;
        }
      },
      getCurrentKeyValue() {
        var context = this.contexts[this.currentContextIndex];
        var tmp = this.getInsertEventText(context);
        if(tmp != null) {
          return tmp;
        }
        else {
          return HIDKey.HIDToKey(context).text;
        }
      },
      //修改按键
      handleModifyKeyCLick() {
        if(this.stopRecordFirst() == false) {
          if(this.currentContextIndex != -1) {
            this.modifyFlag = true;
          }
        }
      },
      handleModifyKeyFocus() {
          this.macroKeyType = 'modify';
          this.$refs.inputKey.visible = true;
          this.$refs.inputModifyKey.$refs.input.blur();
      },
      handleModifyDelayInput(value) {
        if(value) {
          this.contexts[this.currentContextIndex].delay = Math.max(10, Math.min(value,65535));
        }
        else {
          this.contexts[this.currentContextIndex].delay = 10;
        }

        this.saveMacroFirst = true;
        this.saveTipsFlag = true;
      },
      //删除按键
      handleDeleteKeyClick() {
        if(this.stopRecordFirst() == false) {
          if(this.currentContextIndex != -1) {
            this.contexts.splice(this.currentContextIndex,1);
            this.currentContextIndex = -1;
            this.saveMacroFirst = true;
            this.saveTipsFlag = true;
          }
        }
      },
      handleRecordClick() {
        if (this.currentMacroIndex == -1) {
          this.tipsText = this.language.DialogSelectMacroFirst;
          this.$refs.tipsDialog.visible = true;
        }
        else {
          this.isRecording = !this.isRecording;
          this.saveTipsFlag = !this.isRecording; 
        }

        this.recordLabel = this.isRecording ? this.language.StopRecord : this.language.StartRecord;
        this.recordClass = this.isRecording ? "square" : "circle";

        if(this.isRecording)
        {
            // 在全屏模式下监听键盘事件
            document.addEventListener('keydown', this.handleKeyDown);
            document.addEventListener('keyup', this.handleKeyUp);  
            this.contextCount = 0;   
            this.restartRecord = true; 
            this.modifyFlag = false;

            if (parseInt(this.defaultDelay, 10) < 10) {
              this.defaultDelay = '10';
            }

            this.contexts = [];  
        }
        else
        {
          // 组件销毁前移除事件监听
          document.removeEventListener('keydown', this.handleKeyDown);
          document.removeEventListener('keyup', this.handleKeyUp);
          this.saveMacroFirst = true;
        }

        this.$bus.$emit("macroRecording",this.isRecording);
      },
      addKeyContext(event,tmp) {
        event.preventDefault();
        if(this.contexts.length < 70) {
          var delay = 0;
          if(this.restartRecord) {
            this.restartRecord = false;  
            this.lastTimeStamp = event.timeStamp;          
          }
          else {
            delay = Math.floor(event.timeStamp - this.lastTimeStamp);
            this.lastTimeStamp = event.timeStamp;
          }

          if(this.delayAction == 2) {
            delay = this.defaultDelay;
          }

          var keyCode = HIDKey.keyToHID(event.code);

          var context ={
            status:tmp.status,
            type:keyCode.type,
            value:keyCode.value,
            delay:0,
          };

          if(this.contexts.length > 0)
            this.contexts[this.contexts.length - 1].delay = delay;
          this.contexts.push(context);

          this.$nextTick(function() { //此处必须使用nextTick函数,使页面渲染完后再执行
            this.$refs.keyTable.bodyWrapper.scrollTop = 
            this.$refs.keyTable.bodyWrapper.scrollHeight;
          });
        }
        else
        {
          this.tipsText = this.language.DialogMaxMacroKey;
          this.$refs.tipsDialog.visible = true;
        }   
        this.currentContextIndex = this.contexts.length - 1;     
      },
      handleKeyDown(event) {
        if(this.isRecording)
        {
          var tmp = {
            status:0,
            type:1,
            value:event.keyCode,
          }
          this.addKeyContext(event,tmp);
        }
      },
      handleKeyUp(event) {
        if(this.isRecording)
        {
          var tmp = {
            status:1,
            type:1,
            value:event.keyCode,
          }
          this.addKeyContext(event,tmp);
        }
      },
      //默认延时输入框
      handleDefaultDelayBlur() {
        if (parseInt(this.defaultDelay, 10) > 65535) {
          this.defaultDelay = '65535';
        }

        if (parseInt(this.defaultDelay, 10) < 10) {
          this.defaultDelay = '10';
        }
      },
      //循环次数输入框
      handleCycleTimesBlur() {
        if (parseInt(this.cycleTimes, 10) > 250) {
          this.defaultDelay = '250';
        }

        if (parseInt(this.cycleTimes, 10) < 1) {
          this.defaultDelay = '1';
        }
    
        this.saveTipsFlag = true;
      },
      handleInsertEventCommand(command) {
        if (this.currentMacroIndex == -1) {
          this.tipsText = this.language.DialogSelectMacroFirst;
          this.$refs.tipsDialog.visible = true;
        }
        else {
          this.insertCommand = command;

          if(command < 2) {
            this.macroKeyType = 'insert';
            this.$refs.inputKey.visible = true;
          }
          else {
            this.saveMacroFirst = true;
            this.saveTipsFlag = true;
            var index = this.currentContextIndex;
            var push = false;
            
            if(index == -1)
              index = 0;

            if(index == this.contexts.length - 1) {
              push = true;
            }

            var option = UserConvert.String_To_Hex(this.language.InsertEventOptions[command].value);
            var type = option >> 16;
            var value = option & 0xFFFF;

            var context1 = {
              status:1,
              type:type,
              value:value,
              delay:10
            }

            var context0 = {
              status:0,
              type:type,
              value:value,
              delay:10
            }
            if(push)
            {
              this.contexts.push(context0);
              this.contexts.push(context1);
            }
            else {
              this.contexts.splice(index,0,context1);
              this.contexts.splice(index,0,context0); 
            }
            
            this.currentContextIndex = this.contexts.length - 1;
          }
        }
      },
      async handleSaveClick() {
        if(this.currentMacroIndex != -1 && this.saveTipsFlag) {
          var times = 1;
          this.modifyFlag = false;
          if(this.cycleAction == 1) {
            if(this.cycleTimes < 1) {
              this.cycleTimes = 1;
            }

            if(this.cycleTimes > 250) {
              this.cycleTimes = 250;
            }

            times = this.cycleTimes;
          }
          else
            times = this.cycleAction;
          
          this.saveMacroFirst = false;
          this.saveTipsFlag = false;
          var name = this.macros[this.currentMacroIndex].name;
          console.log("handleSaveClick",this.macros,HIDHandle.deviceInfo.mouseCfg.macros,this.currentMacroIndex,name);
          var updateIndex = [];
          //更新鼠标里面的宏
          for(var i = 0;i < HIDHandle.deviceInfo.mouseCfg.macros.length;i++) {         
            if(HIDHandle.deviceInfo.mouseCfg.macros[i].name == name) {
              //更新循环次数
              if(this.macros[this.currentMacroIndex].cycleTimes != times) {
                var tmp = parseInt(times);
                var keyFunction = {
                  type : HIDHandle.MouseKeyFunction.Macro,
                  param:(i << 8) | tmp,
                }

                await HIDHandle.Set_MS_KeyFunction(i,keyFunction);
              }

              //更新宏内容
              var diff = false;
              if(this.macros[this.currentMacroIndex].contexts.length == this.contexts.length) {
                for(var j = 0;j < this.contexts.length;j++) {
                  if((this.macros[this.currentMacroIndex].contexts[j].delay != this.contexts[j].delay) ||
                  (this.macros[this.currentMacroIndex].contexts[j].status != this.contexts[j].status) ||
                  (this.macros[this.currentMacroIndex].contexts[j].type != this.contexts[j].type) ||
                  (this.macros[this.currentMacroIndex].contexts[j].value != this.contexts[j].value)) {
                    updateIndex.push(i);
                    diff = true;
                    break;
                  }
                }
              }
              else {
                updateIndex.push(i);
                diff = true;
              }

              console.log("same name",i,this.contexts);
            }
          }

          this.macros[this.currentMacroIndex].cycleTimes = times;
          this.macros[this.currentMacroIndex].contexts = this.contexts;

          for(var i = 0;i < updateIndex.length;i++) {
            await HIDHandle.Set_MS_Macro(updateIndex[i],this.macros[this.currentMacroIndex]);
          }

          this.saveLocalStorage();
          console.log(this.macros,times);
        }
      },
      macroRowName({row, rowIndex}) {
        if(rowIndex == this.currentMacroIndex)
          return 'select-row';
        else
          return 'unselect-row';
      },
      keyRowName({row, rowIndex}) {
        if(rowIndex == this.currentContextIndex)
          return 'select-row';
        else
          return 'unselect-row';
      },
      saveLocalStorage() {
        localStorage.setItem('macro',JSON.stringify(this.macros));
        this.updateMacroList(this.macros);
      },
      updateMacroList(value) {
        this.$bus.$emit("updateMacroList",value);
      },
      getInsertEventText(context) {
        var tmp = (context.type << 16) + context.value;
        for(var i = 2;i < this.language.InsertEventOptions.length;i++) {
          var option = UserConvert.String_To_Hex(this.language.InsertEventOptions[i].value);
          if(tmp == option) {
            console.log("getInsertEventText",tmp,this.language.InsertEventOptions[i].option);
            return this.language.InsertEventOptions[i].option;
          }
        }

        return null;
      }
    },
    watch: {
      "cycleAction": {
        handler() {
          if(this.currentMacroIndex != -1) {
            if(this.macroChange) {
              this.macroChange = false;
              this.saveTipsFlag = true;
            }
            else {
              this.saveTipsFlag = true;
            }
            console.log("watch cycleAction",this.macroChange,this.saveTipsFlag);
          }
        }
      },
      "saveMacroFirst": {
        handler() {
          this.$bus.$emit("saveMacroFirst",this.saveMacroFirst);
        }
      }
    },
    computed: {
      getKeyText() {
        return context => {
          var tmp = this.getInsertEventText(context);
          if(tmp != null) {
            return tmp;
          }
          else {
            return HIDKey.HIDToKey(context).text;
          }
        }
      }
    },
    created() {
      //加载本地宏
      var macros = localStorage.getItem('macro');
      if(macros) {
        try {
          var macro = [];
          macro.push(JSON.parse(macros));

          for(var i = 0;i < macro[0].length;i++) {
            this.macros.push(macro[0][i]);
          }

          this.updateMacroList(this.macros);
        } catch(e) {
          console.error('Macro parsing error', e);
        }
      }

      //加载鼠标里面的宏
      this.$bus.$on("updateMouseUI",value => {
        for(var i = 0;i < value.macros.length;i++) {        
          if((value.macros[i].name != "") && (value.macros[i].contexts.length >= 2)) {
            var newMacro = true;

            for(var j = 0;j < this.macros.length;j++) {
              if(value.macros[i].name == this.macros[j].name) {
                newMacro = false;
                this.macros[j].contexts = value.macros[i].contexts;
                this.macros[j].cycleTimes = value.macros[i].cycleTimes;
                break;
              }
            }

            if(newMacro) {
              this.macros.push(value.macros[i]);
            }          
          }
        }

        this.saveLocalStorage();
      });

      this.$bus.$on("setMouseMacro",async(index,name) => {
        for(var i = 0;i < this.macros.length;i++) {
          if(name == this.macros[i].name) {
            await HIDHandle.Set_MS_Macro(index,this.macros[i]);
            break;
          }    
        }
      });

      this.$bus.$on("languageChange",lang => {
        if(typeof lang != 'undefined') {
          this.language = lang;

          this.insertEventOptions = lang.InsertEventOptions;
          this.recordLabel = lang.StartRecord;
          this.inputNameText = lang.DialogInputMacroName;
        }
      });

      //确认提示框（删除宏）
      this.$bus.$on("tipsResult",async value => { 
        if(this.$refs.tipsDialog.visible) {
          if(value == true) {
            if(this.tipsText == this.language.DialogDeleteMacro) {
              var name = this.macros[this.currentMacroIndex].name;
              //更新鼠标里面的宏
              for(var i = 0;i < HIDHandle.deviceInfo.mouseCfg.macros.length;i++) {
                if(HIDHandle.deviceInfo.mouseCfg.macros[i].name == name) {
                  await HIDHandle.Restore_MS_Macro(i);
                  this.$bus.$emit("restoreKeyfunction",i);
                }
              }

              this.contexts = [];
              this.macros.splice(this.currentMacroIndex,1);
              this.saveLocalStorage();
              this.currentMacroIndex = -1;
            }
          }
        }    
      }); 

      //确认输入宏名称
      this.$bus.$on("inputName",async value => { 
        if(this.$refs.inputName.visible) {
          if(value.result == true) {
            var found = false;

            for(var i = 0;i < this.macros.length;i++) {
              if(this.macros[i].name == value.name) {
                this.tipsText = this.language.DialogSameMacroName;
                this.$refs.tipsDialog.visible = true;
                found = true;
                break;
              }
            } 
            
            if(found == false) {
              if(this.newMacroName) {
                var macro = {
                  name:value.name,
                  contexts:[],
                  cycleTimes:1
                }
                this.cycleAction = "1";
                this.macros.push(macro);
                this.currentMacroIndex = this.macros.length - 1;
                this.currentContextIndex = -1;
                this.contexts = [];
              } else {
                var name = this.macros[this.currentMacroIndex].name;
                var updateIndex = [];
                //更新鼠标里面的宏名称
                for(var i = 0;i < HIDHandle.deviceInfo.mouseCfg.macros.length;i++) {
                  if(HIDHandle.deviceInfo.mouseCfg.macros[i].name == name) {                 
                    updateIndex.push(i);
                  }
                }
                this.macros[this.currentMacroIndex].name = value.name;

                for(var i = 0;i < updateIndex.length;i++) {
                  await HIDHandle.Set_MS_Macro(updateIndex[i],this.macros[this.currentMacroIndex]);
                }
              }
              this.saveLocalStorage();
            }
          }
        }    
      }); 

      //修改按键/插入按键
      this.$bus.$on("inputKey",async value => {     
        if(value.result == true) {
          var key = HIDKey.textToHID(value.key);
          this.saveMacroFirst = true;
          this.saveTipsFlag = true;
          //插入按键
          if(this.macroKeyType == 'insert') {
            var context = {
              status:this.insertCommand,
              type:key.type,
              value:key.value,
              delay:10,
            };

            var index = this.currentContextIndex;
            var push = false;
            
            if(index == -1)
              index = 0;

            if(index == this.contexts.length - 1) {
              push = true;
            }

            if(push)
              this.contexts.push(context);
            else
              this.contexts.splice(this.currentContextIndex,0,context);
            this.currentContextIndex = this.contexts.length - 1;
          }
          else {
            //修改按键
            this.contexts[this.currentContextIndex].type = key.type;
            this.contexts[this.currentContextIndex].value = key.value;
            this.tmpKeyValue = this.getCurrentKeyValue();
            console.log("input key:",this.contexts);
          }
        }
        this.modifyFlag = false;
      });      
    },
    beforeDestroy() {
      this.$bus.$off("updateMouseUI");
      this.$bus.$off("setMouseMacro");
      this.$bus.$off("languageChange");
      this.$bus.$off("tipsResult");
      this.$bus.$off("inputName");
      this.$bus.$off("inputKey");
    }
  }
</script>

<style lang="less" scoped>
.table_height {
  height:70vh;
}

.sundry_height {
  height:60vh;
}

.delay_height {
  height: 10vh;
}

.cycle_height {
  height: 20vh;
}

.insert_height {
  height: 12vh;
}

.span_delay_size{
  margin-left: calc(var(--font-size) * 0.5);
  width: calc(var(--font-size) * 3);
}

.macro_name_width {
  width: calc(var(--table-width) * 0.8) !important;
}

.macro_name_button_width {
  width: calc(var(--table-width) * 0.2) !important;
}

.mini_button {
  width: calc(var(--font-size) * 2.0);
  height: var(--font-size);
  border-color: transparent;
  &:hover {
    background-color: transparent;
    border-color: transparent;
  }
}


</style>