<template>
  <el-dialog
    ref="fire-key"
    :title="title"
    :visible.sync="visible"
    custom-class="dialog_class"
    @open="onOpen"
    @close="onClose">
    <span slot="footer" class="dialog-footer">
      <div class="flex_column_around_center">
        <span class="dialog_span_padding">{{text}}</span>
        <el-input
        v-model="name"
        @input="handleInputName"
        style="width: 25vw; margin-top: 1vh;"></el-input>
      </div>
      <div class="flex_row_center content_evenly" style="margin-top: 2vh;">
        <el-button @click="handleOKClick" class="dialog_button" lang="OK">确 定</el-button>
        <el-button @click="handleCancelClick" class="dialog_button" lang="Cancel">取 消</el-button>
      </div>
    </span>
  </el-dialog>
</template>
  
<script>
  import UserConvert from '@/assets/js/UserConvert';

  export default {
    extends: 'el-dialog',
    props: {
      title: {
        type: String,
        default: ''
      },
      text: {
        type: String,
        default: ''      
      }
    },
    data() {
      return {
        visible:false,
        name:'',
        maxFlag:false,
      }
    },
    methods: {
      onOpen() {
        // 打开对话框时的逻辑
        this.name = '';
      },
      onClose() {
        // 关闭对话框时的逻辑
      },    
      handleInputName(value) {
        const str = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g;
        var name = value.replace(str, '').replace(/\s/g, '');
        const encoder = new TextEncoder();
        var nameArray = encoder.encode(name);

        //注：中文字符utf-8占3个byte
        //当名字数组大于30时，就需要从最后一个字符往前剔除，直到符合数组长度为30位置
        while(nameArray.length > 30){
          name = name.slice(0, -1);
          nameArray = encoder.encode(name);
        }

        var names = new Uint8Array(nameArray.length);
        for(var i = 0;i < nameArray.length;i++) {
          names[i] = nameArray[i];
        }
        name = UserConvert.UTF8_To_String(names);
        this.name = name;
      }, 
      handleOKClick() {
        var param;
        if(this.name == '') {
          param = {
            result:false,
          }
        }
        else {
          param = {
            result:true,
            name:this.name,
          }
        }

        this.$bus.$emit("inputName",param);
        this.visible = false;
      },
      handleCancelClick() {
        var param = {
          result:false,
        }
        this.$bus.$emit("inputName",param);
        this.visible = false;
      }
    },
    created() {
      this.$bus.$on('backToHome',value => {
        if(value) {
          if(this.visible) {
            this.visible = false;
          }
        }
      });
    },
    beforeDestroy() {
      this.$bus.$off('backToHome');
    }
  }
</script>

<style lang="less" scoped>

</style>