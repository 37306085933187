<template>
  <div>
    <dpi-setting class="dpi_setting"></dpi-setting>
    <report-rate class="report_rate"></report-rate>
    <sensor-setting v-show="sensorShow" class="sensor_setting"></sensor-setting>
    <dpi-effect v-show="effectShow" class="dpi_effect"></dpi-effect>
  </div>
</template>

<script>
  import DpiSetting from '@/components/Sensor/DpiSetting.vue';
  import ReportRate from '@/components/Sensor/ReportRate.vue';
  import SensorSetting from '@/components/Sensor/SensorSetting.vue';
  import DpiEffect from '@/components/Sensor/DpiEffect.vue';
  export default {
    components: {
      DpiSetting,
      ReportRate,
      SensorSetting,
      DpiEffect
    },
    data() {
      return {
        sensorShow:true,
        effectShow:true,
        sensor:{}
      }
    },
    created() {
      this.$bus.$on("setGlobalSensor",value => {
        this.sensor = value;
      });  

      this.$bus.$on("setMouseDefaultCfg",deviceCfg => {
        this.sensorShow = this.sensor.Setting.includes(deviceCfg.sensor);
        this.effectShow = typeof deviceCfg.dpiEffect != "undefined";
      }) 
    },
    beforeDestroy() {
      this.$bus.$off("setMouseDefaultCfg");
      this.$bus.$off("setGlobalSensor");
    }
  }
</script>

<style lang="less" scoped>
  .dpi_setting {
    height:25vh;
  }

  .report_rate {
    height: 15vh;
  }

  .sensor_setting {
    height: 20vh;
  }

  .dpi_effect {
    height: 15vh;
    width: 35vw;
  }
</style>