<template>
  <el-dialog
    ref="fire-key"
    :title="title"
    :visible.sync="visible"
    custom-class="dialog_class"
    @open="onOpen"
    @close="onClose">
    <span slot="footer" class="dialog-footer">
      <div class="flex_column_around_center">
        <div class="flex_row content_between">
          <span class="width_class" lang="DialogFireKeyTimes">次数（0-3）</span>
          <el-input 
          v-model.number="times" 
          @blur="handleTimesBlur"
          class="el-input_width"></el-input>
        </div>
        <div class="flex_row content_between">
          <span class="width_class" lang="DialogFireKeyInterval">间隔（10-255）</span>
          <el-input 
          v-model.number="interval"
          @blur="handleIntervalBlur"
          class="el-input_width"></el-input>
        </div>
        <span class="dialog_span_padding" lang="DialogFireKeyTips">次数设置为0时，按下按键一直发，松开按键结束</span>
      </div>
      <div class="flex_row_center content_evenly" style="margin-top: 3vh;">
        <el-button @click="handleOKClick" class="dialog_button" lang="OK">确 定</el-button>
        <el-button @click="handleCancelClick" class="dialog_button" lang="Cancel">取 消</el-button>
      </div>
    </span>
  </el-dialog>
</template>
  
<script>
  export default {
    extends: 'el-dialog',
    props: {
      title: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        visible:false,
        times:3,
        interval:10,
        result:{},
      }
    },
    methods: {
      onOpen() {
        // 打开对话框时的逻辑
        this.result = {
          result:false,
        }
      },
      onClose() {
        // 关闭对话框时的逻辑
        console.log("fire key close",this.result);
        this.$bus.$emit("fireKey",this.result);
      },
      handleTimesBlur() {
        if(this.times < 0)
          this.times = 0;
        if(this.times > 3)
          this.times = 3;
      },
      handleIntervalBlur() {
        if(this.interval < 10)
          this.interval = 10;
        if(this.interval > 255)
          this.interval = 255;
      },
      handleOKClick() {
          this.result = {
          result:true,
          times:this.times,
          interval:this.interval,
        }

        this.visible = false;
      },
      handleCancelClick() {
        this.visible = false;
      }
    },
    created() {
      this.$bus.$on('backToHome',value => {
        if(value) {
          if(this.visible) {
            this.visible = false;
          }
        }
      });
    },
    beforeDestroy() {
      this.$bus.$off('backToHome');
    }
  }
</script>

<style lang="less" scoped>
  .el-input_width {
    width: calc(var(--font-size) * 6);
  }

  .width_class {
    width: calc(var(--font-size) * 12);
  }
</style>