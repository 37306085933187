<template>
  <div>
    <h1>Compx Page</h1>
    <p>The dynamic id is: {{ $route.params.id }}</p>
  </div>
</template>
 
<script>
export default {
  name: 'Compx',
  created() {
    console.log('Dynamic page with id: ' + this.$route.params.id);
  }
};
</script>